import { size, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: ${size.xl4}px;
    flex-direction: row;
    gap: ${size.xl3}px;
  }
`;

export const Details = styled.div`
  margin-bottom: ${size.xl1}px;
  flex: 0 0 35%;

  .sub-headline {
    margin-bottom: ${size.lg}px;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${size.sm}px;

  .headline-icon {
    margin-right: ${size.sm}px;
    &-img {
      padding: ${size.sm}px;
    }
  }
`;

export const CtaWrapper = styled.div`
  a {
    color: ${colors.green5};

    &:hover,
    &:active {
      color: ${colors.teal5};
    }
  }
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const SvgIcon = styled.img`
  width: ${size.xl1}px;
  height: ${size.xl1}px;
`;
