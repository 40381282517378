import { Container, H3, H4, size } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { getActivePromo, Promo } from 'utils/helpers/activePromoHelpers';
import useScreenerData from 'utils/hooks/useScreenerData';
import { CTA, ImageType, ProductSection } from 'utils/types';

import AnchorLink from '../../components/AnchorLink';
import BulletPoints from '../../components/BulletPoints';
import ProductDisplayGrid from '../../components/ProductDisplayGrid';
import * as S from './ProductCategorySection.styles';

export type ProductCategorySectionProps = {
  content: {
    headline?: string;
    headlineIcon?: ImageType;
    subHeadline?: string;
    cta?: CTA;
    productSection?: ProductSection;
    advertisedPromos?: Promo[];
  };
  cardTrackingLabel: string;
  addToCartTrackingLabel: string;
};

/**
 * Products for a category with a bullet point list
 */
export default function ProductCategorySection({
  content: {
    headline,
    headlineIcon,
    subHeadline,
    productSection,
    cta,
    advertisedPromos,
  },
  cardTrackingLabel,
  addToCartTrackingLabel,
}: ProductCategorySectionProps): JSX.Element {
  const { bulletPoints, products, productBackgroundColorList } =
    productSection || {};

  const pageScreenerData = useScreenerData();

  const productsWithScreenerData = useMemo(
    () =>
      products
        ?.filter((product) => product)
        .map((product) => {
          const productScreenerData = pageScreenerData[product?.slug];

          return {
            ...product,
            screener: productScreenerData?.screener,
            disclaimer: productScreenerData?.disclaimer,
          };
        }),
    [pageScreenerData, products],
  );

  const activePromo = useMemo(
    () => getActivePromo(advertisedPromos || []),
    [advertisedPromos],
  );

  const onCtaClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.PRODUCT_CATEGORY_CLICK,
      data: {
        headline,
        cta: cta?.text || 'No CTA provided',
      },
    });
  };

  return (
    <Container>
      <S.Container>
        <S.Details>
          <S.HeadlineWrapper>
            {/* Icon is an image */}
            {headlineIcon?.gatsbyImageData ? (
              <GatsbyImage
                image={headlineIcon.gatsbyImageData}
                alt={headlineIcon.description}
                style={{
                  width: `${size.xl1}px`,
                  height: `${size.xl1}px`,
                }}
                className="headline-icon headline-icon-img"
              />
            ) : (
              // Icon is SVG
              <div className="headline-icon">
                <S.SvgIcon
                  src={headlineIcon?.file?.url}
                  alt={headlineIcon?.description}
                ></S.SvgIcon>
              </div>
            )}

            <H3>{headline}</H3>
          </S.HeadlineWrapper>

          <H4 className="sub-headline">{subHeadline}</H4>

          {bulletPoints ? <BulletPoints bulletPoints={bulletPoints} /> : null}

          <S.CtaWrapper>
            {cta ? (
              <AnchorLink
                href={`#${cta?.url?.toLowerCase()}`}
                onClick={onCtaClick}
                offset="40"
              >
                {cta?.text}
              </AnchorLink>
            ) : null}
          </S.CtaWrapper>
        </S.Details>

        <S.GridWrapper>
          {productsWithScreenerData ? (
            <ProductDisplayGrid
              products={productsWithScreenerData}
              activePromo={activePromo}
              columnConfig={{
                tabletVerticalUpColumns: 1,
                tabletHorizontalUpColums: 2,
              }}
              productBackgroundColorList={productBackgroundColorList}
              cardTrackingLabel={cardTrackingLabel}
              addToCartTrackingLabel={addToCartTrackingLabel}
            ></ProductDisplayGrid>
          ) : null}
        </S.GridWrapper>
      </S.Container>
    </Container>
  );
}
