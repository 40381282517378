import { DisclaimerDataProps } from 'components/GeneralDisclaimer';
import { ScreenerDataProps } from 'components/PrePurchaseScreener';
import { graphql, useStaticQuery } from 'gatsby';

type PageScreenerData = {
  product: {
    slug: string;
  };
  screener: ScreenerDataProps;
  disclaimer: DisclaimerDataProps;
};

// Map - slug with screener and disclaimer
type ProductWithScreener = Record<string, PageScreenerData>;

type PageScreenerResult = {
  nodes: PageScreenerData[];
};

const getAllScreenerData = (
  allContentfulPdpPage: PageScreenerResult,
): ProductWithScreener => {
  const productsWithScreener: ProductWithScreener = {};
  allContentfulPdpPage?.nodes
    .filter((page) => page?.product?.slug)
    .forEach((page) => {
      productsWithScreener[page.product.slug] = {
        product: page.product,
        screener: page.screener,
        disclaimer: page.disclaimer,
      };
    });

  return productsWithScreener;
};

export default function useScreenerData(): ProductWithScreener {
  const { allContentfulPdpPage } = useStaticQuery(graphql`
    query {
      allContentfulPdpPage {
        nodes {
          product {
            slug
          }
          disclaimer {
            title
            body {
              raw
            }
            callToAction {
              url
              text
            }
          }
          screener {
            options {
              items
              label
              value
            }
            callToActionText
            description
            title
          }
        }
      }
    }
  `);

  const allProductScreenerData = getAllScreenerData(allContentfulPdpPage);

  return allProductScreenerData;
}
